export const convertBase64ToBlobUrl = (base64Pdf: string) => {
  // Convert base64 to Blob using Buffer
  const byteArray = Buffer.from(base64Pdf, "base64");
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // Create URL for the blob
  const url = window.URL.createObjectURL(blob);

  return url;
};
