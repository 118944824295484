import React from "react";
import MaterialTable, {
  MTableCell,
  MTableEditField,
  MTableBodyRow,
  MTableEditRow
} from "material-table";
import { Paper, Typography, Box, Button } from "@material-ui/core";
import { untouchShipments } from "../api";
import { Icon, CircularProgress } from "@material-ui/core";
import { useLang } from "../context/lang";
import NavPdfDownloader, {
  NavPdfDownloaderMany
} from "../components/NavPdfDownloader";
import { DownloadPdf } from "../components/PdfGenerator/PdfGenerator";
import _ from "lodash";
import { generateTrip } from "../utils/trip";
import { useTrips } from "../context/trips";
import { IMovement, ITrip, Movement } from "navision-proxy-api/@types/terminal";
import { useViewSettings } from "../context/viewSettings";
import { useTripsApiActions } from "../hooks/useTripsApiActions";
import InfiniteScrollTableBody from "../components/InfiniteScrollTableBody";
import { useMovementsConfirm } from "../context/movementsConfirm";

export const AllMovementsTable = ({ onOpen }: any): any => {
  const { trips, search, isSearchable, saveUntouchLine, saveLine } = useTrips();

  const { updateApproveTripLines } = useTripsApiActions();

  const { selectMovements } = useMovementsConfirm();
  const { t } = useLang();

  const { fields, isTripsReversed } = useViewSettings();

  const [movements, setMovements] = React.useState<Movement[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [selectedMovements, setSelectedMovements] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (search.length == 0 || isSearchable()) {
      //ignore reload when search is still entering
      computeMovements();
    }
  }, [trips, search]);

  const computeMovements = () => {
    const reducedMovements = trips.reduce(
      (acc: any, trip: any) => [
        ...acc,
        ...trip.Lines.map((l: any) => ({ ...l, Trailer: trip.Trailer }))
      ],
      []
    );

    const filteredMovements = reducedMovements
      ?.filter(
        ({
          PartialTripNr,
          Vehicle,
          StartTime,
          ShipmentUnits,
          SenderName,
          AddresseeName,
          ShipmentNr,
          ShipmentRemark,
          BrandAndNo,
          PlanInfo,
          ...rest
        }: any) => {
          if (isSearchable()) {
            //start search from 3 symbols entered
            const match =
              search.length === 0 ||
              PartialTripNr?.toLowerCase().includes(search) ||
              SenderName?.toLowerCase()
                .latinise()
                .includes(search.latinise()) ||
              AddresseeName?.toLowerCase()
                .latinise()
                .includes(search.latinise()) ||
              Vehicle?.toLowerCase().includes(search) ||
              StartTime?.toLowerCase().includes(search) ||
              ShipmentNr?.toString().includes(search) ||
              ShipmentRemark?.toLowerCase().includes(search) ||
              BrandAndNo?.toLowerCase().includes(search) ||
              PlanInfo?.toLowerCase().includes(search);
            //   if (match) {
            //     console.log(rest);
            //   }
            return match;
          } else {
            return true;
          }
        }
      )
      .sort((a: any, b: any) => {
        if (
          (a.touchedDate && b.touchedDate) ||
          (!a.touchedDate && !b.touchedDate)
        ) {
          return a.PartialTripNr > b.PartialTripNr
            ? 1
            : a.PartialTripNr == b.PartialTripNr
            ? 0
            : -1;
        } else if (a.touchedDate && !b.touchedDate) {
          return 1;
        } else if (!a.touchedDate && b.touchedDate) {
          return -1;
        }
        return 0;
      });

    if (isTripsReversed) {
      filteredMovements.reverse();
    }

    setMovements(filteredMovements);
  };

  React.useEffect(() => {
    //triger hook on movements calculated
    if (onOpen) onOpen();
  }, [movements]);

  const resetChanges = async (line: any) => {
    setLoading(true);
    saveUntouchLine(line);
    await untouchShipments([line]);
    setLoading(false);
  };

  const handleUpdateRow = async (newline: Movement) => {
    setLoading(true);

    const updatedLines = await updateApproveTripLines(
      newline.PartialTripNr,
      [newline],
      [newline]
    );

    setLoading(false);
    if (updatedLines) {
      saveLine(updatedLines[0]);
    }
    //loadPlans({ ignoreCache: false });
  };

  return (
    <>
      {selectedMovements.length > 0 && (
        <Box>
          {/* <Typography>
            {`${t("selected")} ${selectedMovements.length} ${t("movements")}`}
          </Typography>
          <Typography>{t("actions")}:</Typography> */}
          <Box my={1} mt={2} mr={1} display="flex" width={300} height={36}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => selectMovements(selectedMovements)}
            >
              {t("receive")}
            </Button>
          </Box>
          <Box my={1} mr={1} display="flex" width={300} height={36}>
            <DownloadPdf button trip={generateTrip(selectedMovements)} />
          </Box>
          <Box my={1} display="flex" width={300} height={36}>
            <NavPdfDownloaderMany
              shipmentIds={selectedMovements.map(
                ({ ShipmentNr }) => ShipmentNr
              )}
            />
          </Box>
        </Box>
      )}
      <Table
        fields={fields}
        movements={movements}
        setSelectedMovements={setSelectedMovements}
        loading={loading}
        resetChanges={resetChanges}
        handleUpdateRow={handleUpdateRow}
        onOpen={onOpen}
      />
    </>
  );
};

export const Table = React.memo(
  ({
    fields,
    movements,
    setSelectedMovements,
    loading,
    resetChanges,
    handleUpdateRow,
    onOpen,
    minimized = false
  }: any) => {
    const tableRef = React.useRef<any>();
    const { t } = useLang();

    const isCellEditable = (cellProps: any) => {
      const isPackage = cellProps.columnDef.field?.includes("Packages.");
      if (isPackage) {
        const packageName = cellProps.columnDef.field?.split(".")[1];
        const isPackageValueAvailable =
          packageName in cellProps.rowData.Packages;
        //if there is no package key for this row we show no
        if (!isPackageValueAvailable) {
          return false;
        }
      }
      return true;
    };

    const tableContainerRef = React.useRef<HTMLElement>(null);
    // /** remove tab navigation */
    // React.useEffect(() => {
    //   if (tableContainerRef.current) {
    //     [...tableContainerRef.current.getElementsByTagName("TH")].forEach(
    //       el => {
    //         if (el.firstElementChild) {
    //           el.firstElementChild.setAttribute("tabindex", "-1");
    //           if (el.firstElementChild.firstElementChild) {
    //             el.firstElementChild.firstElementChild.setAttribute(
    //               "tabindex",
    //               "-1"
    //             );
    //           }
    //         }
    //       }
    //     );
    //   }
    // });

    const editButtonRef = React.useRef<HTMLElement>(null);

    /** arrow navigation */
    React.useEffect(() => {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    const handleKeyDown = (e: any) => {
      const inputs = Array.from(
        document.getElementsByClassName("navigatableField")
      );
      if (document.activeElement) {
        const activeIndex = inputs.indexOf(document.activeElement);

        if (activeIndex > -1) {
          switch (e.key) {
            case "ArrowRight":
              e.preventDefault();
              (
                inputs[(activeIndex + 1) % inputs.length] as HTMLElement
              ).focus();
              break;
            case "ArrowLeft":
              e.preventDefault();
              (
                inputs[
                  activeIndex === 0 ? inputs.length - 1 : activeIndex - 1
                ] as HTMLElement
              ).focus();
              break;
          }
        }
        if (e.key === "Enter" && editButtonRef.current) {
          editButtonRef.current.click();
        }
      }
    };

    let editAllActions: ((e: any) => any)[] = [];

    return (
      <>
        {/* <Button
          onClick={e => {
            editAllActions.forEach(action => action(e));
          }}
        >
          edit all
        </Button> */}
        <MaterialTable
          columns={fields}
          data={movements}
          options={{
            selection: true,
            showTitle: true,
            //tableLayout: "fixed",
            doubleHorizontalScroll: false,
            minBodyHeight: !minimized ? "calc( 100vh - 280px)" : undefined,
            maxBodyHeight: !minimized ? "calc( 100vh - 280px)" : undefined,
            paging: false,
            sorting: true,
            toolbar: false,
            rowStyle: rowData => {
              return rowData.touchedDate ? { backgroundColor: "#D1E0D2" } : {};
            }
          }}
          tableRef={tableRef}
          onSelectionChange={movements => setSelectedMovements(movements)}
          actions={[
            {
              name: "unconfirm",
              icon: () =>
                loading ? (
                  <CircularProgress color="primary" size="2rem" />
                ) : (
                  <Icon color="error">undo</Icon>
                ),
              tooltip: t("undoRow"),
              onClick: (e: any, row: any) => resetChanges(row),
              hidden: false, //value will be overwriten on Row Component customizing
              // because of https://github.com/mbrn/material-table/issues/676#issuecomment-629389985
              position: "row"
            } as any,
            {
              name: "pdf",
              icon: () => {}, //value will be overwriten on Row Component customizing
              //hidden: booking.type === "Buffer",
              tooltip: t("downloadPdf"),
              position: "row",
              onClick: (e, data) => {}
            }
          ]}
          title={" "}
          editable={{
            onRowUpdate: handleUpdateRow
          }}
          components={{
            Body: (
              bodyProps //infinite scroll body
            ) => (
              <InfiniteScrollTableBody
                {...bodyProps}
                scrollWidth={undefined}
                tableRef={tableRef}
                onRender={onOpen}
              />
            ),
            // EditRow: props => {
            //   console.log("edit props", props);
            //   return <MTableEditRow {...props} />;
            // },
            EditField: props => {
              if (isCellEditable(props)) {
                if (Array.isArray(props.value)) {
                  //for multiple Units
                  return props.value.map((v: any, i: number) => (
                    <MTableEditField
                      {...props}
                      value={v}
                      onChange={(newValue: any) => {
                        const newArrayValue = props.value;
                        newArrayValue[i] = String(newValue);
                        props.onChange(newArrayValue);
                      }}
                      autoFocus={props.columnDef.field === "LoadedQty"}
                      inputProps={{
                        className: "navigatableField"
                      }}
                    />
                  ));
                } else {
                  return (
                    <MTableEditField
                      {...props}
                      autoFocus={props.columnDef.field === "LoadedQty"}
                      inputProps={{
                        className: "navigatableField"
                      }}
                    />
                  );
                }
              }
              return <MTableCell {...props} value="" />;
            },
            Cell: props => {
              //checking if there is a package and a package value if available for this row
              if (
                //field is highlighable
                [
                  "PartialTripNr",
                  "Vehicle",
                  "SenderName",
                  "AddresseeName"
                ].includes(props.columnDef.field)
              ) {
                return props.columnDef.render ? ( //we use render function for custom rendering
                  <MTableCell {...props} value="" />
                ) : (
                  <MTableCell {...props} value="">
                    <span className="search-highlightable">
                      {isCellEditable(props) ? props.value : ""}
                    </span>
                  </MTableCell>
                );
              } else {
                return props.columnDef.render ? ( //we use render function for custom rendering
                  <MTableCell {...props} value="" />
                ) : (
                  <MTableCell {...props} value="">
                    {isCellEditable(props)
                      ? Array.isArray(props.value)
                        ? props.value.map((v: any) => <div>{v}</div>)
                        : props.value
                      : ""}
                  </MTableCell>
                );
              }
            },
            Container: props => <Paper ref={tableContainerRef} {...props} />,
            Row: props => {
              //overwriding actions properties based on row data
              //doing it here since it not possible to do it in an actions array together with slection
              //https://github.com/mbrn/material-table/issues/676#issuecomment-629389985
              const propsCopy = { ...props };

              //edit action is the last one without a name for some reason
              const activateEdit = (event: any) =>
                propsCopy.actions[propsCopy.actions.length - 1]?.(
                  propsCopy.data
                )?.onClick(event, propsCopy.data);

              editAllActions.push(activateEdit);
              propsCopy.actions.find(
                (a: any) => a.name === "unconfirm"
              ).hidden = !Boolean(propsCopy.data.touchedDate);

              propsCopy.actions.find((a: any) => a.name === "pdf").icon =
                () => (
                  <NavPdfDownloader
                    key={propsCopy.data.TripNr}
                    shipmentId={propsCopy.data.ShipmentNr}
                  />
                );

              return <MTableBodyRow {...propsCopy} />;
            }
          }}
          icons={{
            Check: (() => <Icon ref={editButtonRef}>check</Icon>) as any
          }}
          localization={{
            body: {
              editTooltip: t("edit"),
              editRow: {
                cancelTooltip: t("reset"),
                saveTooltip: t("save")
              }
            },
            header: {
              actions: t("actions")
            },
            pagination: {
              firstTooltip: t("firstRow"),
              previousTooltip: t("previousRow"),
              nextTooltip: t("nextRow"),
              lastTooltip: t("lastRow"),
              labelRowsSelect: t("rows")
            },
            toolbar: {
              searchTooltip: t("searchTable"),
              searchPlaceholder: t("searchTable")
            }
          }}
        />
      </>
    );
  },
  (prevProps: any, nextProps: any) =>
    prevProps.movements.length == nextProps.movements.length && //check on filtering
    prevProps.movements?.every(
      ({ ShipmentNr }: any, index: number) =>
        ShipmentNr == nextProps.movements[index]?.ShipmentNr
    ) && //check on sorting
    prevProps.fields.length == nextProps.fields.length &&
    prevProps.loading == nextProps.loading
);
