export const addTime = (hours: any, time: any) => {
  const t = (parseInt(time) + hours) % 24;
  return t < 10 ? "0" + t : t;
};

/** this function faking a local time to be a UTC
 * This is how it is used in NAV...
 */
export const toLocalISOString = (date: Date): string => {
  const timezoneOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime = new Date(date.getTime() - timezoneOffset).toISOString();
  //.slice(0, -1); // we not removing Z letter on the end faking that it is UTC time
  return localISOTime;
};
