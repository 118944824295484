import Axios, { AxiosRequestConfig } from "axios";

import { API_BASE_URL } from "./config/api";
import { createBrowserHistory } from "history";
import {
  IPlan,
  TerminalAppConfigurations,
  ClientTerminalAppConfigurations,
  TerminalUser,
  Movement,
  ITrip,
  PlanTripRequestBody,
  PlanTripResponseBody,
  ReceiveContainersRequestBody
} from "navision-proxy-api/@types/terminal";
import { PackageContainerInfo } from "./widgets/ReceiveMovements/components/ContainersTable";
import {
  NAVBookingWebCustomersCustomer,
  NAVPartialTrip,
  NAVTrailer,
  NAVVehicle
} from "navision-proxy-api/@types/navTypes";

declare module "axios" {
  interface AxiosRequestConfig {
    /**Used as a cache key for update actions */
    latestFetchUrl?: string;
  }
}

const axios = Axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "X-Link-Token": localStorage.getItem("linkToken") || "",
    //TODO: unhaarcode it when integrating to other companies
    CompanyName: "Alex Andersen Ølund"
  }
});

//for mocking api
//const sleep = time => new Promise(resolve => setTimeout(resolve, time));

//   axios.interceptors.request.use(
//     config => {
//       config.headers.authorization = `Basic ${localStorage.getItem(
//         'accessToken'
//       )}`
//       return config
//     },
//     error => Promise.reject(error)
//   )

axios.interceptors.response.use(
  response => response,
  error => {
    console.log("Error from api interceptors.");
    const errorMessage =
      error?.response?.data || error?.response?.data?.error || error.message;

    // Get request URL and headers from error object
    const requestHeaders = error?.config?.headers;
    const hasLinkToken = requestHeaders?.["X-Link-Token"];

    //if we were trying to authenticate with link token
    //so no need to redirect to classic login
    if (hasLinkToken) {
      console.log("authWithLinkToken error");
      return Promise.reject(error);
    }

    // if (error?.response?.status == 401) {
    //   //user is not authenificated
    //   localStorage.clear();
    //   console.log(window.location.pathname);

    //   if (!window.location.pathname.endsWith("/login")) {
    //     console.log("back");
    //     createBrowserHistory().push("/login");
    //     window.location.reload();
    //   }

    //   return Promise.reject(error);
    // }

    console.error(errorMessage);
    return Promise.reject({ ...error, message: errorMessage }); //propogate error forward
    //return { data: {} };
  }
);

export async function login(username: string, password: string) {
  /*
    Set into userDepartment name into the localstorage and check the dataset where you should get it from .
  */
  const response = await axios.post(
    `/login?username=${username}&password=${password}`
  );
  try {
    localStorage.setItem("username", username);
    localStorage.setItem("token", response.data.appConfigurations.token);

    axios.defaults.headers[
      "Authorization"
    ] = `Bearer ${response.data.appConfigurations.token}`;
    axios.defaults.headers["CompanyName"] = "Alex Andersen Ølund";
    return response.data;
  } catch (err: any) {
    console.log("err");
    console.log(err);
    throw new Error(
      `Invalid credentials or invalid data from Umraco. ${err.message}`
    );
  }
}

export function saveLinkToken(linkToken: string) {
  axios.defaults.headers["X-Link-Token"] = linkToken;
}

export async function logout() {
  //save the user app configuration
  await axios.post("/logout", {
    username: localStorage.getItem("username"),
    appConfigurations: {
      ...localStorage
    }
  });
}

export async function fetchPlans({
  dateFilter = null,
  departmentAdresses,
  departmentPlans,
  ignoreCache = false,
  filterMode = "outgoing"
}: any = {}): Promise<{
  packages: string[];
  Plans: IPlan[];
}> {
  const response = await axios.get(`/shipments`, {
    params: {
      //name: department,
      date: dateFilter,
      adresses:
        departmentAdresses || localStorage.getItem("departmentAdresses"),
      plans: departmentPlans || localStorage.getItem("departmentPlans"),
      filterMode,
      userId: localStorage.getItem("userId")
    },
    headers: {
      "ignore-cache": ignoreCache
    }
  });
  if (response.request) {
    const fetchUrl = new URL(response.request?.responseURL);
    //we use it as cache key on the backend in order to update cached values
    axios.defaults.latestFetchUrl = fetchUrl.pathname + fetchUrl.search;
  }
  return response.data;
}

export const fetchDepartmentConfig = async (department: any) => {
  const { data } = await axios.get(`/departmentConfig`, {
    params: {
      department
    }
  });
  return data;
};

export async function updateTrips(trip: ITrip) {
  const response = await axios.put(`/trip`, {
    trip,
    meta: {
      dataUrl: axios.defaults.latestFetchUrl,
      username: localStorage.getItem("username")
    }
  });
  return response.data;
}

export async function updateMovements(movements: Movement[]) {
  const response = await axios.put(`/movements`, {
    movements,
    meta: {
      dataUrl: axios.defaults.latestFetchUrl,
      username: localStorage.getItem("username")
    }
  });
  return response.data;
}

export async function untouchShipments(lines: any = []) {
  const response = await axios.post(`/untouch`, {
    lines,
    meta: { dataUrl: axios.defaults.latestFetchUrl }
  });
  return response.data;
}

export async function fetchUnits() {
  const { data } = await axios.get(`/units`);
  return data;
}

export async function fetchPackages() {
  const { data } = await axios.get("/packages");
  return data;
}

/** old part of parking app, TODO: remove it */
export async function addDriver(driver: any) {
  const { data } = await axios.post("/drivers", driver);
  return data;
}

export async function fetchDrivers() {
  const { data } = await axios.get("/drivers");
  return data;
}

export async function updateDriver(driver: any) {
  const { data } = await axios.put("/drivers", driver);
  return data;
}

export async function fetchTrucks() {
  const { data } = await axios.get("/trucks");
  return data;
}

export async function fetchTrailers() {
  const { data } = await axios.get("/trailers");
  return data;
}

export async function fetchMorningList() {
  const { data } = await axios.get("/morningList");
  return data;
}

export async function addMorningList(entry: any) {
  try {
    return await axios.post(`/morningList?code=${entry}`);
  } catch (e: any) {
    console.error(e.message);
  }
}

export async function fetchDaylist() {
  const { data } = await axios.get("/daylist");
  return data;
}

export async function fetchVehicle(type: any) {
  const { data } = await axios.get(`/daylist?vehicleType=${type}`);
  return data;
}

export async function updateVehicle(vehicle: any) {
  const { data } = await axios.put("/vehicles", vehicle);
  return data;
}

export async function updateDaylist(dayList: any, userGroup = null) {
  if (userGroup) {
    const { data } = await axios.post(
      `/dayList?codes=${dayList}&userGroup=${userGroup}`
    );
    return data;
  }

  const { data } = await axios.post(`/dayList?codes=${dayList}`);
  return data;
}

export async function deleteFromMorningList(codes: any) {
  const { data } = await axios.delete(`/morningList?codes=${codes}`);
  return data;
}

export async function getTrips(department: any, date = "") {}

// export async function addTruck(truckID) {
//   const { data } = await axios.post(`/vehicle${truckID}`);
//   return data;
// }

export async function addVehicle(vehicle: any) {
  const { data } = await axios.post(`/vehicles`, vehicle);
  return data;
}

export async function addTruckAndTrailerPair(truckId: any, trailers: any) {
  const { data } = await axios.post(
    `/vehicle/${truckId}/pair?trailerCodes=${trailers}`
  );
  return data;
}

export async function unPairTruckAndTrailer(truckId: any) {
  const { data } = await axios.post(`/vehicle/${truckId}/unpair`);
  return data;
}

export async function addSupdepartmentToVehicle(
  truckID: any,
  subdepartmentId: any
) {
  const { data } = await axios.post(
    `/vehicle/${truckID}/subdepartment?id=${subdepartmentId}`
  );
  return data;
}

/** finish of old part of parking app, TODO: remove it */

export const getShipmentPdf = async (shipmentId: any) => {
  const { data } = await axios.post("/forwardToNav", {
    url: `/BookingWebWaybill(${shipmentId})`,
    method: "get"
  });
  return data.Pdf;
};

export const saveUserApi = async (
  username: any,
  appConfigurations: ClientTerminalAppConfigurations
) => {
  const { data } = await axios.post("/saveUser", {
    username,
    appConfigurations
  });
  return data;
};

export const fetchUserApi = async (username: string) => {
  const { data } = await axios.post("/fetchTerminalUser", { username });

  return data as TerminalUser;
};
export const checkShipmentsAlerts = async (
  shipmentDate: string,
  timeFrom: string
) => {
  const { data } = await axios.post("/checkShipmentsAlerts", {
    shipmentDate,
    timeFrom,
    username: localStorage.getItem("username")
  });
  return data;
};

export const registerContainer = async (
  containers: PackageContainerInfo[],
  info: {
    employeeNr: string;
    comment: string;
    settlmentAddressCode: string;
    settlementAddressCCItem: NAVBookingWebCustomersCustomer;
  }
) => {
  const { data } = await axios.post("/containers", {
    containers,
    info: {
      ...info,
      username: localStorage.getItem("username"),
      departmentName: localStorage.getItem("departmentName"),
      counterpartCode: localStorage.getItem("userCounterpart"),
      containerJournal: localStorage.getItem("containerJournal")
    }
  } as ReceiveContainersRequestBody);
  return data;
};

export const trackShipmnetQR = async (trackingUrl: string) => {
  const { data } = await axios.get(trackingUrl);
  return data;
};

export const planMovementsToTrip = async (
  planTripRequest: PlanTripRequestBody
) => {
  const { data } = await axios.post("/planTrip", planTripRequest);
  return data as PlanTripResponseBody;
};

export const lookupTrailer = async (substring: string) => {
  const { data } = await axios.get(`/lookupTrailer?substring=${substring}`);
  return data as NAVTrailer[];
};

export const lookupVehicle = async (substring: string) => {
  const { data } = await axios.get(`/lookupVehicle?substring=${substring}`);
  return data as NAVVehicle[];
};

export const lookupPartialTrip = async (vehicle?: string, trailer?: string) => {
  const { data } = await axios.get(
    `/lookupPartialTrips?${vehicle ? `vehicle=${vehicle}` : ""}${
      trailer ? `&trailer=${trailer}` : ""
    }`
  );
  return data as NAVPartialTrip[];
};

export const findEmployee = async (employeeNr: string) => {
  const { data } = await axios.get(
    `/findEmployee?employeeNumber=${employeeNr}`
  );
  return data;
};

export const loadCounterpartCCLookup = async (counterpartCode: string) => {
  const { data } = await axios.get(
    `/counterpartCCLookup?counterpartCode=${counterpartCode}`
  );
  return data;
};

export const checkContainersBalance = async (
  containerSettlmentAddressCode: string,
  date: string | undefined
) => {
  const { data } = await axios.get(
    `/checkContainersBalance?containerSettlmentAddressCode=${containerSettlmentAddressCode}&date=${date}`
  );
  return data;
};

export const generateLinkAccessToken = async (params: {
  planDepartments: string[];
  startAddresses: string[];
  shipmentDate: string;
  senderName: string;
  filterMode?: string;
}) => {
  const { data } = await axios.post("/generateLinkAccessToken", {
    ...params
  });
  return data.token;
};

export const authWithLinkToken = async (accessToken: string) => {
  const response = await axios.get("/authWithLinkToken", {
    headers: {
      "X-Link-Token": `${accessToken}`
    }
  });
  return response.data;
};
