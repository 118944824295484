export const APP_PREFIX_URL = process.env.REACT_APP_PREFIX || "";

export const USER_SAVE_INTERVAL = 1000;
export const DEFAULT_DATE_FILTER =
  process.env.REACT_APP_ENV == "local"
    ? //planning test date ? new Date("2025-01-31T00:00:00")
      new Date()
    : //  new Date()
      new Date();

// used mainly for testing when there is no shipmnets data from NAV
// so we show default packages
// TODO: fetch it from NAV
export const DEFAULT_CONTAINER_BALANCE = {
  "T6 CC": 0,
  HYLDER: 0,
  "FORL&dot;": 0
};
