import React, { ReactElement, SyntheticEvent, ReactChildren, FC } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useLang } from "./lang";
import util from "util";
import { Box } from "@material-ui/core";

type MessageType = "success" | "error" | "warning";

interface ISnackbarContext {
  openSnackbar: (
    message: string,
    severity?: MessageType,
    messageAction?: { action: () => any; title: string }
  ) => void;
}

interface ISnackbar {
  isOpen: boolean;
  severity?: MessageType;
  message?: string;
  messageAction?: { action: () => any; title: string };
}

export const SnackbarContext = React.createContext<ISnackbarContext>(
  {} as ISnackbarContext
);

function SnackbarProvider(props: {
  children: JSX.Element | JSX.Element[];
}): ReactElement {
  const { t } = useLang();
  const [snackbars, setSnackbars] = React.useState<ISnackbar[]>([]);

  const openSnackbar = (
    message: string,
    severity: MessageType = "success",
    messageAction?: { action: () => any; title: string }
  ) => {
    setSnackbars(prev => {
      if (prev.some(({ message }) => message == `${t(message)}`)) {
        return prev;
      } else {
        return [
          ...prev,
          {
            isOpen: true,
            message: `${t(message)}`,
            severity,
            messageAction
          }
        ];
      }
    });
  };

  const handleClose = (
    event: SyntheticEvent<Element, Event>,
    reason: string,
    index: number
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbars(prev => prev.filter((_: any, i: number) => i != index));
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={true}
        style={{ maxWidth: "96vw" }}
        autoHideDuration={
          snackbars.some(
            snackbar =>
              snackbar.severity == "error" || snackbar.severity == "warning"
          )
            ? null
            : 5000
        }
        //onClose={(event, reason) => handleClose(event, reason, i)}
      >
        <Box display="flex" flexDirection="column">
          {snackbars.map((snackbar, i) => (
            <Box mb={2}>
              <MuiAlert
                id={`message-${snackbar.severity}`}
                elevation={6}
                variant="filled"
                onClose={event => handleClose(event, "close", i)}
                severity={snackbar.severity}
                style={{ whiteSpace: "pre-line" }}
              >
                {snackbar.severity == "error"
                  ? util.inspect(snackbar.message)
                  : snackbar.message}

                {snackbar.messageAction && (
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                    mt={1}
                  >
                    <Button
                      variant="outlined"
                      style={{ color: "white", borderColor: "white" }}
                      onClick={e => {
                        snackbar.messageAction?.action();
                        handleClose(e, "close", i);
                      }}
                    >
                      {snackbar.messageAction.title}
                    </Button>
                  </Box>
                )}
              </MuiAlert>
            </Box>
          ))}
        </Box>
      </Snackbar>

      <SnackbarContext.Provider
        value={{
          openSnackbar
        }}
        {...props}
      />
    </>
  );
}

const useSnackbar = () => React.useContext(SnackbarContext);

export { useSnackbar, SnackbarProvider };
