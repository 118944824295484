import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet
} from "@react-pdf/renderer";
import printJS from "print-js";

import QRCode from "qrcode";

import { BlobProvider, pdf } from "@react-pdf/renderer";
import { Loader } from "../Loader";
import { useLang } from "../../context/lang";
//import { getDeliveryDate } from "containers/BookingDetails";
import { Button } from "@material-ui/core";
import { IMovement, Movement } from "navision-proxy-api/@types/terminal";

const getStyles = (scale = 1) => {
  return StyleSheet.create({
    page: {
      // display: "flex",
      // flexDirection: "column",
      // backgroundColor: "white",
      // height: "300px",
      // flexWrap: "wrap"
      fontSize: 8 * scale,
      fontWeight: 300,
      padding: 13 * scale
    },
    header: {
      width: "50%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center"
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    h1: {
      fontSize: 11 * scale,
      fontWeight: 700,
      marginBottom: 1 * scale
    },
    h2: {
      fontSize: 9 * scale,
      fontWeight: 700
    },
    h2black: {
      fontSize: 9 * scale,
      fontWeight: 900
    },
    h3: {
      fontSize: 8 * scale,
      fontWeight: 600,
      marginBottom: 5 * scale
    },
    smallText: {
      fontSize: 4 * scale
    },
    midText: {
      fontSize: 6 * scale
    },
    boldText: {
      fontWeight: 700
    },
    headerImage: {
      height: 30 * scale
    },
    flexBox: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      marginBottom: 5 * scale
    },
    body: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      width: 90 * scale,
      height: 184 * scale
    },
    wrapBox: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    container: {
      marginTop: 5 * scale,
      marginBottom: 5 * scale
    },
    containerText: {
      width: "100%"
    },
    containerQr: {
      position: "absolute",
      bottom: 0 * scale,
      right: -28,
      display: "flex",
      justifyContent: "flex-end"
    },
    qr: {
      width: 100 * scale,
      height: 100 * scale
    },
    marginTop: {
      marginTop: 7 * scale
    },
    marginTopBig: {
      marginTop: 12 * scale
    },
    marginBottom: {
      marginBottom: 10 * scale
    },
    main: {
      width: 121 * scale,
      height: 164 * scale,
      position: "relative"
    }
  });
};

export const getMovementId = (movement: Movement) => {
  return `${movement.ShipmentNr}_${movement.MovementNr}`;
};

export const generateMovementsQr = async (movements: Movement[]) => {
  const qrs: { [movementId: string]: string[] } = {};
  await Promise.all(
    movements.map(async (movement): Promise<void> => {
      const labelCount = parseInt(
        String(
          movement.LoadedQty.length > 0
            ? movement.LoadedQty.reduce(
                (sum, qty) => sum + (parseInt(qty as unknown as string) || 0),
                0
              )
            : movement.Quantity.reduce(
                (sum, qty) => sum + (parseInt(qty as unknown as string) || 0),
                0
              ) || "0"
        )
      );

      for (const labelIndex of [...Array(labelCount).keys()]) {
        // const qr = await QRCode.toDataURL(
        //   `${process.env.REACT_APP_SHIPMENT_API_URL}trackShipment?trackingNumber=${movement.trackingShipmentNumber}&trackingIndex=${labelIndex}`,
        //   { errorCorrectionLevel: "L" }
        // );
        const qr = await QRCode.toDataURL(
          `${movement.trackingShipmentNumber}_${labelIndex}`,
          { errorCorrectionLevel: "L" }
        );

        if (!qrs[getMovementId(movement)]) {
          qrs[getMovementId(movement)] = [];
        }

        qrs[getMovementId(movement)].push(qr);
      }
    })
  );
  return qrs;
};

// Create styles

export const PrintLabelsPdfButton = ({
  movements,
  titleKey = "printLabels",
  buttonProps,
  preHook
}: {
  movements: Movement[];
  titleKey?: string;
  buttonProps?: React.ComponentProps<typeof Button>;
  preHook?: () => Promise<void>;
}) => {
  const [isReady, setIsReady] = React.useState(false);
  const isClicked = React.useRef(false);
  const qrRefs = React.useRef<any>({});

  const handleDownload = async (e: any) => {
    qrRefs.current = await generateMovementsQr(movements);
    setIsReady(true);
  };

  const { t } = useLang();

  return isReady ? (
    <BlobProvider
      document={
        <LabelsPdfTemplate
          t={t}
          movements={movements}
          movementsQr={qrRefs.current}
        />
      }
      //   style={{ color: "inherit", textDecoration: "none", height: "24px" }}
    >
      {({ blob, url, loading, error }) => {
        //print pdf
        const handlePrint = async () => {
          if (preHook) {
            await preHook();
          }
          if (url) {
            printJS({ printable: url, type: "pdf" });

            //isClicked.current = true;
          }
        };

        //init print for first time
        handlePrint();

        return (
          <Loader margin={5} loading={loading}>
            <Button
              onClick={e => {
                handlePrint(); //start print for non first times
              }}
              color="primary"
              variant="outlined"
              {...buttonProps}
            >
              {t(titleKey)}
            </Button>
          </Loader>
        );
      }}
    </BlobProvider>
  ) : (
    <Button
      color="primary"
      variant="outlined"
      {...buttonProps}
      onClick={handleDownload}
    >
      {t(titleKey)}
    </Button>
  );
};

export const printPdfLabel = async (
  movements: IMovement[],
  t: (key: string) => string
) => {
  const qrs = await generateMovementsQr(movements);

  const blob = await pdf(
    <LabelsPdfTemplate t={t} movements={movements} movementsQr={qrs} />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  printJS({ printable: url, type: "pdf" });
};

export const DownloadLabelsPdfButton = ({
  movements
}: {
  movements: Movement[];
}) => {
  const [isReady, setIsReady] = React.useState(false);
  const isClicked = React.useRef(false);
  const qrRefs = React.useRef<any>({});

  const handleDownload = async (e: any) => {
    qrRefs.current = await generateMovementsQr(movements);

    setIsReady(true);
  };

  const { t } = useLang();

  return isReady ? (
    <BlobProvider
      document={
        <LabelsPdfTemplate
          t={t}
          movements={movements}
          movementsQr={qrRefs.current}
        />
      }
      //   style={{ color: "inherit", textDecoration: "none", height: "24px" }}
    >
      {({ blob, url, loading, error }) => {
        //download pdf
        const tempLink = document.createElement("a");
        tempLink.href = url || "";
        tempLink.setAttribute(
          "download",
          `labels_${new Date().toISOString()}.pdf`
        );

        const download = () => {
          if (url && !isClicked.current) {
            tempLink.click();
            isClicked.current = true;
          }
        };
        download();

        //print pdf

        return (
          <Loader margin={5} loading={loading}>
            <Button
              onClick={e => {
                tempLink.click();
              }}
              color="primary"
              variant="outlined"
            >
              {t("downloadLabels")}
            </Button>
          </Loader>
        );
      }}
    </BlobProvider>
  ) : (
    <Button color="primary" variant="outlined" onClick={handleDownload}>
      {t("downloadLabels")}
    </Button>
  );
};

interface ILabelsPdfTemplateProps {
  t: (key: string) => string;
  movements: IMovement[];
  movementsQr: { [key: string]: string[] };
}

const pageSizeScales = {
  A8: 1,
  A6: 2
};

// Create Document Component
export const LabelsPdfTemplate = ({
  t,
  movements,
  movementsQr
}: //
ILabelsPdfTemplateProps) => {
  const pageSize = "A6";
  const styles = getStyles(pageSizeScales[pageSize]);
  return (
    <Document>
      {movements
        .map(movement =>
          movement.Unit.map((unit, unitIndex) => {
            const labelCount = parseInt(
              String(
                (movement.LoadedQty[unitIndex] ||
                  movement.Quantity[unitIndex] ||
                  "0") as string
              )
            );

            return [...Array(labelCount).keys()].map(
              (goodNumber, labelIndex, labelCountArray) => {
                const labelCount = labelCountArray.length;
                return (
                  <Page
                    size={pageSize}
                    style={styles.page}
                    orientation="portrait"
                  >
                    <View style={styles.main}>
                      <View style={styles.header}>
                        <View style={styles.headerContainer}>
                          <Text style={styles.smallText}>
                            {"Shipment ID: "}
                          </Text>
                          <Text style={styles.h2}>{movement.ShipmentNr}</Text>
                          {movement.ShipmentRemark && (
                            <Text
                              style={styles.smallText}
                            >{`Remark: ${movement.ShipmentRemark}`}</Text>
                          )}
                        </View>
                        {/* <Image src={pdfHeader} style={styles.headerImage} /> */}
                      </View>
                      <View style={styles.marginTop}>
                        <Text style={styles.smallText}>{"Receiver: "}</Text>
                        <Text style={styles.h2black}>
                          {movement.AddresseeName}
                        </Text>
                        {movement.AddresseeName !==
                          movement.RawMovement.UnloadAddressName && (
                          <View style={styles.marginTopBig}>
                            <Text style={styles.smallText}>
                              {"Delivery at: "}
                            </Text>
                            <Text style={styles.midText}>
                              {movement.RawMovement.UnloadAddressName}
                            </Text>
                          </View>
                        )}
                        {/* <Text style={styles.midText}>
                      {booking.deliveryStreet + ", " + booking.deliveryLocation}
                    </Text> */}
                        {/* <Text style={styles.midText}>
                      {booking.deliveryAddress2}
                    </Text> */}
                        <View style={styles.body}>
                          <View style={styles.containerText}>
                            <View style={styles.container}>
                              <View>
                                <Text
                                  style={styles.smallText}
                                >{`Latest delivery Date:  `}</Text>
                                <Text style={styles.midText}>
                                  {movement.RawMovement.EndDate?.[0]}
                                </Text>
                              </View>
                            </View>
                            <Text style={styles.smallText}>{"Sender: "}</Text>
                            <View style={styles.wrapBox}>
                              <Text style={styles.h3}>
                                {`${movement.SenderName}`}
                              </Text>
                            </View>
                            <View style={styles.marginTop}>
                              {movement.ActualGate && (
                                <View>
                                  <Text style={styles.midText}>
                                    {"Gate: " + movement.ActualGate}
                                  </Text>
                                </View>
                              )}
                            </View>
                            <View style={styles.marginTop}>
                              {movement.RawMovement.RouteGuide && (
                                <View>
                                  <Text style={styles.smallText}>
                                    {"Route: " +
                                      movement.RawMovement.RouteGuide}
                                  </Text>
                                </View>
                              )}
                            </View>

                            <View style={styles.marginTop}>
                              <View style={styles.flexBox}>
                                <Text style={styles.midText}>
                                  {`${unit?.replaceAll("&dot;", ".")} `}
                                </Text>
                                <Text style={styles.midText}>{` ${
                                  goodNumber + 1
                                } of ${labelCount}`}</Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.containerQr}>
                        <View style={styles.qr}>
                          <Image
                            src={
                              movementsQr[getMovementId(movement)][labelIndex]
                            }
                          />
                        </View>
                      </View>
                    </View>
                  </Page>
                );
              }
            );
          })
        )
        .flat()
        .flat()}
    </Document>
  );
};
