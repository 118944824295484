import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useMovementsConfirm } from "../../../context/movementsConfirm";
import { Box, Button, Typography } from "@material-ui/core";
import { useLang } from "../../../context/lang";
import MovementsTable from "./ReceiveMovements";
import ReceiveMovements from "./ReceiveMovements";
import { ContainersTable } from "../components/ContainersTable";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80vw",
    overflow: "auto"
  }
}));

export default () => {
  const classes = useStyles();
  // const { selectedMovements, selectMovements } = useMovementsConfirm();
  const submitContainersRef = React.useRef<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { t } = useLang();

  //const driver = selectedMovements?.map(m => m.Driver).join(", ");

  // const isOpen = selectedMovements?.length > 0;

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    if (submitContainersRef.current) submitContainersRef.current();
  };

  return (
    <Box
      mx={2}
      mt={{ xs: 2, md: 0 }}
      justifyContent={{ xs: "center", md: "flex-start" }}
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        {t("receiveContainers")}
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isOpen}>
          <>
            <div className={classes.paper}>
              {" "}
              <ContainersTable
                fullWidth
                // hideIn
                submitRef={submitContainersRef}
                onClose={handleClose}
              />
              <Box mt={2}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  {t("createReceipt")}
                </Button>
              </Box>
            </div>
          </>
        </Fade>
      </Modal>
    </Box>
  );
};
