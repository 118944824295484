import React, { useState } from "react";
import { bulkEditPatch } from "../../../utils/materialTableExtentions";
import { useMovementsConfirm } from "../../../context/movementsConfirm";
import { Movement } from "navision-proxy-api/@types/terminal";
import { useLang } from "../../../context/lang";
import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import {
  checkContainersBalance,
  findEmployee,
  loadCounterpartCCLookup,
  registerContainer
} from "../../../api";
import { useSnackbar } from "../../../context/snackbar";
import { useViewSettings } from "../../../context/viewSettings";
import {
  NAVBookingWebCustomersCustomer,
  NAVEmployee
} from "navision-proxy-api/@types/navTypes";
import { Loader } from "../../../components/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { get } from "lodash";
import { useAuth } from "../../../context/auth";
import { useStore } from "../../../context/store";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { toLocalISOString } from "../../../utils/date";
import outImage from "../../../assets/img/out.png";
import inImage from "../../../assets/img/in.png";
import printJS from "print-js";
import { convertBase64ToBlobUrl } from "../../../utils/pdf";
import _ from "lodash";
import { DEFAULT_CONTAINER_BALANCE } from "../../../config/app";

export type PackageContainerInfo = {
  packageName: string;
  packageKey: string;
  in: number;
  out: number;
};

const AddNewRow = ({
  onAdd,
  options
}: {
  onAdd: (option: string) => void;
  options: string[];
}) => {
  const { t } = useLang();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: string) => {
    onAdd(option);
    handleClose();
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Icon>add</Icon>}
        onClick={handleButtonClick}
      >
        {t("addNewRow")}
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleOptionClick(option)}>
            {t(option)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const getTotalColumns = (
  t: any,
  onAdd: (option: string) => void,
  options: string[],
  hideIn?: boolean,
  containersBalanceMap?: Record<string, number>,
  loadingBalance?: boolean
): Column<PackageContainerInfo>[] => {
  const columns: any = [
    {
      title: <AddNewRow onAdd={onAdd} options={options} />,
      field: "packageName",
      hidden: false,
      sorting: false,
      editable: "never",
      selectOnFocus: true,
      render: (rowData: any) => {
        const balance = containersBalanceMap?.[rowData.packageKey] || "";
        return (
          <Box>
            <Typography>{rowData.packageName}</Typography>
            {balance != "" && (
              <Typography variant="caption">{`(${balance})`}</Typography>
            )}
            {loadingBalance && (
              <Box display="flex" alignItems="center">
                (
                <Typography variant="caption">{t("loadingBalance")}</Typography>
                <Box width={30}>
                  <Loader loading />
                </Box>
                )
              </Box>
            )}
          </Box>
        );
      }
    },
    {
      title: (
        <Box display="flex" flexDirection="column">
          <Typography>{t("out")}</Typography>
          <img width={70} src={outImage} />
        </Box>
      ),
      field: "out",
      hidden: false,
      editable: "always",
      type: "numeric",
      selectOnFocus: true
    }
  ];

  if (!hideIn) {
    columns.push({
      title: (
        <Box display="flex" flexDirection="column">
          <Typography>{t("in")}</Typography>
          <img width={70} src={inImage} />
        </Box>
      ),
      field: "in",
      hidden: false,
      editable: "always",
      type: "numeric",
      selectOnFocus: true
    });
  }

  // columns.push({
  //   title: t("balance"),
  //   field: "balance",
  //   hidden: false,
  //   editable: "never",
  //   type: "numeric"
  // });

  return columns;
};

const getInitTotalData = (
  movements: Movement[],
  fields: Column<Movement>[],
  t: any
): PackageContainerInfo[] => {
  let totalIn = fields
    .filter(
      ({ field, hidden }) =>
        field && field.includes("Package") && hidden == false
    )
    .reduce<{ [packageName: string]: number }>(
      (acc, { field }: any) => ({
        ...acc,
        [field.replace("Packages.", "")]: 0
      }),
      {}
    );

  if (_.isEmpty(totalIn)) {
    totalIn = DEFAULT_CONTAINER_BALANCE;
  }
  console.log(totalIn);
  movements?.forEach(movement => {
    Object.keys(totalIn).forEach((key: any) => {
      totalIn[key] += parseInt(String(movement.Packages[key] || "0"));
    });

    // totalIn["T6 CC"] += parseInt(String(movement.Packages["T6 CC"] || "0"));
    // totalIn["HYLDER"] += parseInt(String(movement.Packages["HYLDER"] || "0"));
    // totalIn["FORL&dot;"] += parseInt(
    //   String(movement.Packages["FORL&dot;"] || "0")
    // );
  });

  return Object.entries(totalIn)?.map(([packageName, inValue]) => ({
    packageName: t(`${packageName}`),
    packageKey: packageName,
    in: inValue,
    out: 0
  }));
};

type LookupItem = {
  key: string;
  value: string;
  ccItem: NAVBookingWebCustomersCustomer;
};

export const ContainersTable = ({
  submitRef: submitTotalRef,
  fullWidth = false,
  hideIn = false,
  onClose = () => {}
}: {
  submitRef: React.MutableRefObject<any>;
  fullWidth?: boolean;
  hideIn?: boolean;
  onClose?: () => void;
}) => {
  const { selectedMovements, selectMovements } = useMovementsConfirm();
  const { t } = useLang();

  const { fields } = useViewSettings();
  const { openSnackbar } = useSnackbar();
  const { userCounterpart } = useAuth();
  const { latestDateFilter } = useStore();

  const [employeeLookup, setEmployeeLookup] = useState<NAVEmployee[]>([]);
  const [loadingEmployee, setLoadingEmployee] = useState(false);

  const [ccLookup, setCCLookup] = useState<LookupItem[]>([]);
  const [loadingCCLookup, setLoadingCCLookup] = useState(false);
  const [containerSettlmentAddress, setContainerSettlmentAddress] =
    useState<LookupItem>();

  const loadCCLookup = async () => {
    setLoadingCCLookup(true);
    const lookup = await loadCounterpartCCLookup(String(userCounterpart));
    const filteredLookup = lookup.filter(
      (item: LookupItem) => item.key !== userCounterpart
    );
    setCCLookup(filteredLookup);
    setLoadingCCLookup(false);
  };

  React.useEffect(() => {
    loadCCLookup();
  }, []);

  const [data, setData] = useState<PackageContainerInfo[]>([]);
  const packageOptions = fields
    .filter(({ field }) => field && field.includes("Package"))
    .map(({ field }) => (field ? field.replace("Packages.", "") : ""));

  React.useEffect(() => {
    const totalData = () => getInitTotalData(selectedMovements, fields, t);
    setData(totalData);
  }, [fields]);

  const handleAddRow = (option: string) =>
    setData(prev => [
      ...prev,
      { packageName: t(option), packageKey: option, in: 0, out: 0 }
    ]);

  const [containersBalanceMap, setContainersBalanceMap] = useState<
    Record<string, number>
  >({});

  const [loadingBalance, setLoadingBalance] = React.useState(false);

  const totalColumns = React.useMemo(
    () =>
      getTotalColumns(
        t,
        handleAddRow,
        packageOptions,
        hideIn,
        containersBalanceMap,
        loadingBalance
      ),
    [containersBalanceMap, loadingBalance]
  );

  const [totalLoading, setTotalLoading] = React.useState(false);

  const [employee, setEmployee] = React.useState<NAVEmployee>();
  const [vehicle, setVehicle] = React.useState("");
  const [comment, setComment] = React.useState("");

  //MATERIAL TABLE BULK EDIT PATCH
  //TODO: move it inside wrapper component or/and inside material table
  //hack for controlling table edit mode

  const startEditTotalRef = React.useRef<any>(null);

  const handleEditTotal = async (
    editedData: Record<
      number,
      {
        oldData: PackageContainerInfo;
        newData: PackageContainerInfo;
      }
    >
  ) => {
    try {
      setTotalLoading(true);
      const containers: PackageContainerInfo[] = data.map((packageInfo, i) => {
        if (editedData[i]) {
          return editedData[i].newData;
        } else {
          return packageInfo;
        }
      });
      if (!employee) {
        openSnackbar("Employee number is required", "error");
        setTotalLoading(false);
        return;
      }
      const result = await registerContainer(containers, {
        employeeNr: employee?.No,
        comment: `${vehicle ? `Vehicle:${vehicle}` : ""} ${
          comment ? `Comment:${comment}` : ""
        }`,
        settlmentAddressCode: containerSettlmentAddress?.key || "",
        settlementAddressCCItem: containerSettlmentAddress?.ccItem || {}
      });
      console.log(result);
      const url = convertBase64ToBlobUrl(result.pdf);
      printJS({ printable: url, type: "pdf" });
      openSnackbar(
        `Containers received successfully. Entry number: ${result.containerEntry.Entry_No}`,
        "success"
      );
      onClose();
    } catch (e: any) {
      console.error(e);
      openSnackbar(e?.message, "error");
    }
    setTotalLoading(false);
  };

  const editTotalPatch = bulkEditPatch<PackageContainerInfo>(
    submitTotalRef,
    startEditTotalRef,
    handleEditTotal
  );

  React.useEffect(() => {
    if (startEditTotalRef.current && totalLoading == false) {
      startEditTotalRef.current();
    }
  }, [totalLoading]);

  //END MATERIAL TABLE BULK EDIT PATCH
  //TODO: move it to wrapper componenet

  const loadEmployeeLookup = async (employeeNumber: string) => {
    setLoadingEmployee(true);
    const lookup = await findEmployee(employeeNumber);
    setEmployeeLookup(lookup);
    setLoadingEmployee(false);
  };

  // Auto-select employee when only one option exists
  React.useEffect(() => {
    if (employeeLookup.length === 1) {
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        keyCode: 13,
        code: "Enter",
        which: 13,
        bubbles: true
      });
      document?.activeElement?.dispatchEvent(event);

      // (
      //   document.getElementsByClassName(
      //     "MuiAutocomplete-popupIndicator"
      //   )?.[0] as any
      // )?.click(); // trigger unfocus autocomplete
    }
  }, [employeeLookup]);

  const loadContainerBalance = async () => {
    try {
      if (!containerSettlmentAddress) return;
      setLoadingBalance(true);
      console.log("container address");
      console.log(toLocalISOString(latestDateFilter.current || new Date()));
      const containersBalanceMap = await checkContainersBalance(
        String(containerSettlmentAddress?.key),
        toLocalISOString(latestDateFilter.current || new Date())
      );
      setContainersBalanceMap(containersBalanceMap);
    } catch (e: any) {
      console.error(e);
      openSnackbar(e?.message, "error");
    } finally {
      setLoadingBalance(false);
    }
  };

  React.useEffect(() => {
    if (containerSettlmentAddress) {
      loadContainerBalance();
    }
  }, [containerSettlmentAddress]);

  return (
    <Box
      id="container-registration"
      width={{ xs: "100%", md: fullWidth ? "100%" : "60%" }}
    >
      <Typography variant={"h5"}>{t("createContainersReceipt")}</Typography>
      <Box
        display={{ xs: "block", md: "flex" }}
        flexWrap={"wrap"}
        flexDirection={"row"}
        gridGap={10}
        mb={2}
      >
        <Box width={{ xs: "100%", md: "45%" }}>
          <Autocomplete<NAVEmployee>
            id={"employeeNumber"}
            popupIcon={
              loadingEmployee ? <Loader loading /> : <ArrowDropDownIcon />
            }
            options={employeeLookup}
            noOptionsText={""}
            value={employee}
            onChange={(e, value) => {
              if (value) {
                setEmployee(value);
              }
            }}
            onInputChange={(e, value) => {
              if (value.length == 4 && employeeLookup.length == 0) {
                loadEmployeeLookup(value);
              } else if (value.length < 4 && employeeLookup.length > 0) {
                setEmployeeLookup([]);
              }
            }}
            // forcePopupIcon={!Boolean(vehicle)}
            autoHighlight
            renderOption={option => (
              <Typography variant="body2">{`${option.First_Name} ${option.Last_Name} (${option.No})`}</Typography>
            )}
            getOptionLabel={option =>
              `${option.First_Name} ${option.Last_Name} (${option.No})`
            }
            //open={false}
            autoSelect
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  label={t("employeeNumber")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              );
            }}
            //PaperComponent={() => null} // Prevent dropdown from rendering
            disablePortal
            // popupIcon={<ArrowDropDownIcon />}
          />
        </Box>
        <Box width={{ xs: "100%", md: "45%" }}>
          <Autocomplete<LookupItem>
            id={"settlmentAddress"}
            options={ccLookup}
            value={containerSettlmentAddress}
            popupIcon={
              loadingCCLookup ? <Loader loading /> : <ArrowDropDownIcon />
            }
            onChange={(e, value: any) => {
              if (value) {
                setContainerSettlmentAddress(value);
              }
            }}
            // forcePopupIcon={!Boolean(vehicle)}
            autoHighlight
            renderOption={option => (
              <Typography variant="body2">{`${option.value}`}</Typography>
            )}
            getOptionLabel={option => `${option.value}`}
            autoSelect
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  label={t("settlmentAddress")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              );
            }}
            disablePortal
            // popupIcon={<ArrowDropDownIcon />}
          />
        </Box>
        <Box width={{ xs: "100%", md: "45%" }}>
          <TextField
            variant="outlined"
            fullWidth
            value={vehicle}
            onChange={(event: any) => setVehicle(event.target.value)}
            label={t("vehicle")}
          />
        </Box>
        <Box width={{ xs: "100%", md: "45%" }}>
          <TextField
            variant="outlined"
            fullWidth
            value={comment}
            onChange={(event: any) => setComment(event.target.value)}
            label={t("comment")}
          />
        </Box>
      </Box>
      <Divider />
      <MaterialTable
        isLoading={totalLoading}
        columns={totalColumns}
        editable={editTotalPatch.editableActions}
        components={{
          ...editTotalPatch.editComponents
        }}
        data={data}
        options={{
          selection: false,
          paging: false,
          ...editTotalPatch.editableOptions
        }}
      />
    </Box>
  );
};
